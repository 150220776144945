import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {environment} from '../../deploy/environment';
import { formatDate } from '../../core/utils/date'
import Table from '../../components/table/table';
import { getPromotions, setPromotion, deletePromotion, updatePromotion } from '../../resources/services';
import { PromotionModal } from './modals/promotion-modal';

import './promociones.css';

class ManagePromotions extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loadError: false,
      data:[],
      showPromotionModal: false,
      selectedPromotionData: null,
      isVideoOpen: false,
      videoId: null
    };

    this.handleOnClosePromotionModal = this.handleOnClosePromotionModal.bind(this)
    this.handlePromotionCreation = this.handlePromotionCreation.bind(this)

    this.columns = [
      {
        name: 'Descripción',
        selector: 'DESCRIPTION',
        sortable: true,
      },
      {
        name: 'Válida desde',
        selector: 'VALID_FROM',
        sortable: true,
        cell: (row) => {
          return formatDate(row.VALID_FROM)
        },
      },
      {
        name: 'Válida hasta',
        selector: 'VALID_TO',
        sortable: true,
        cell: (row) => {
          return formatDate(row.VALID_TO)
        }
      },
      {
        name: 'Imagen',
        sortable: false,
        cell: (row) =>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => this.openImage(row)}
          ><i className="entypo-eye"></i>
          </button>
      },
      {
        name: 'Acciones',
        cell: (row) => {
          return [
            <button
              type="button"
              className="btn btn-default"
              style={{ marginRight: '10px' }}
              onClick={() => this.editPromotion(row)}
            ><i className="entypo-pencil"></i>
            </button>,
            <button
              type="button"
              className="btn btn-red"
              onClick={() => this.deletePromotion(row.ID)}
            ><i className="entypo-trash"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];
  }

  componentDidMount() {
    this.loadPromotions()
  }

  openImage(promotion) {
    const promotionImageUrl = environment.urlPromotionsResources + promotion.IMAGE
    window.open(promotionImageUrl, '_blank', 'noopener,noreferrer')
  }

  editPromotion(promotionData) {
    this.setState({showPromotionModal: true, selectedPromotionData: promotionData})
  }

  deletePromotion(promotionId){
    window.confirm(`Estás seguro de que deseas eliminar la promoción?`) && deletePromotion(promotionId)
      .then((result) => {
        if(result.response && result.deleted != 0){
          this.loadPromotions()
        }
      })
  }

  loadPromotions() {
    this.setState({ loading: true })
    getPromotions()
      .then((result) => {
        if(result?.response){
          this.setState({
            data: result.promotions
          })
        }else{
          toast("Se ha producido un error al realizar la consulta", {type: toast.TYPE.ERROR})
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleOnClosePromotionModal = () => {
    this.setState({ showPromotionModal: false, selectedPromotionData: null })
  }

  handlePromotionCreation = (promotionId, description, validFrom, validTo, image) => {
    this.setState({ loading: true })
    const reqPromise = promotionId
      ? updatePromotion(promotionId, description, validFrom, validTo)
      : setPromotion(image, description, validFrom, validTo)

    reqPromise
      .then((result) => {
        this.setState({ loading: false, showPromotionModal: false })
        if (result) {
          if (result.created == 1 || result.updated == 1) {
            toast(`La promoción se ha ${promotionId ? 'actualizado' : 'creado'} con éxito`, {
              type: toast.TYPE.SUCCESS
            })
          } else {
            toast('Se ha producido un error', {
              type: toast.TYPE.ERROR
            })
          }
        }
      })
      .finally(() => {
        this.loadPromotions()
      })
  }

  render() {
    return (
      <Fragment>
        <div className="text-right row-control">
          <button className="btn btn-primary" onClick={() => this.setState({showPromotionModal: true, selectedPromotionData: null})}>
            <i className="entypo-plus"></i> Añadir promoción
          </button>
        </div>
        <Table
          keyFilter={'DESCRIPTION'}
          data={this.state.data}
          columns={this.columns}
          title="Promociones"
          pagination
        />
        {this.state.showPromotionModal && (
          <PromotionModal
            show={this.state.showPromotionModal}
            onClose={this.handleOnClosePromotionModal}
            onCreate={this.handlePromotionCreation}
            tutorialData={this.state.selectedPromotionData}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(ManagePromotions)