import { environment } from './../deploy/environment'

// configuration to pdf

const { root, baseUrl } = environment

// Internal functions
const sendFetch = (url, method, body = {}) => {
  // POST
  if (method === 'POST') {
    return fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
        ukey: window.localStorage.getItem('ukey'),
        version: 1
      }
    })
      .then((response) => {
        if (response.status === 200) {
          const r = response.json()
          if (r.response === 0) {
            alert('Response 0')
          }
          return r
        } else {
          throw new Error(response.status)
        }
      })
      .catch((err) => {
        console.log('err', err)
        alert('Error al procesar la consulta')
      })
  } else {
    // GET
    return fetch(url, {
      method,
      headers: {
        ukey: window.localStorage.getItem('ukey'),
        version: 1
      }
    })
      .then((response) => {
        if (response.status === 200) {
          const r = response.json()
          if (r.response === 0) {
            alert('Response 0')
          }
          return r
        } else {
          throw new Error(response.status)
        }
      })
      .catch((err) => {
        console.log('err', err)
        alert('Error al procesar la consulta')
      })
  }
}

// External funcions
export const getObject = (obj) => {
  return Object.keys(obj).map((v) => {
    const objKeys = obj[v].components
    if (obj[v].title === 'Cliente') {
      return {}
    }
    return {
      title: obj[v].title,
      components: objKeys
        .map((c) => {
          const r = {
            label: c.properties.label,
            name: c.properties.name,
            value: c.properties.value
          }
          if (c.properties.options) {
            r.options = c.properties.options
          }
          if (c.properties.group) {
            r.options = c.properties.group
          }
          return r
        })
        .filter((value) => Object.keys(value).length !== 0)
    }
  })
}

export const isLogged = () => {
  return sendFetch(root + '/api/ajax/isLogged/', 'GET').then((result) => {
    if (result && result.response) {
      return result
    }
    return false
  })
}

export const signIn = (username, password) => {
  return sendFetch(root + '/api/ajax/signIn/', 'POST', {
    username,
    password
  }).then((response) => {
    return response
  })
}

/* ---- USERS ----- */
export const getUsers = (u = null) => {
  let url = root + '/api/ajax/getUsers/'
  if (u) {
    url += '?u=' + u
  }
  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

export const setUser = (post) => {
  return sendFetch(root + '/api/ajax/setUser/', 'POST', { ...post }).then(
    (response) => {
      return response
    }
  )
}

export const deleteUser = (u) => {
  return sendFetch(root + '/api/ajax/deleteUser/', 'POST', u).then(
    (response) => {
      return response
    }
  )
}

/* ----- CUSTOMERS ------ */
export const getCustomers = (u = null) => {
  let url = root + '/api/ajax/getCustomers/'
  if (u) {
    url += '?u=' + u
  }
  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

export const setCustomer = (post) => {
  return sendFetch(root + '/api/ajax/setCustomer/', 'POST', { ...post }).then(
    (response) => {
      return response
    }
  )
}

export const deleteCustomer = (u) => {
  return sendFetch(root + '/api/ajax/deleteCustomer/', 'POST', u).then(
    (response) => {
      return response
    }
  )
}

export const checkCustomer = (e) => {
  let url = root + '/api/ajax/checkCustomer/?e=' + e
  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

/* ----- PODOLOGIST ------ */
export const getPodologist = (u = null) => {
  let url = root + '/api/ajax/getPodologist/'
  if (u) {
    url += '?u=' + u
  }
  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

export const setPodologist = (post) => {
  return sendFetch(root + '/api/ajax/setPodologist/', 'POST', { ...post }).then(
    (response) => {
      return response
    }
  )
}

export const deletePodologist = (u) => {
  return sendFetch(root + '/api/ajax/deletePodologist/', 'POST', u).then(
    (response) => {
      return response
    }
  )
}

/* ----- ORDERS ------ */
export const getOrders = (u = null, s = 0, f = null) => {
  let url = root + '/api/ajax/getOrders/'
  if (u || s) {
    url += '?u=' + u + '&s=' + s
  }
  if (f) {
    url += '?f=' + f
  }
  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

export const setOrders = (post) => {
  return sendFetch(root + '/api/ajax/setOrders/', 'POST', { ...post }).then(
    (response) => {
      return response
    }
  )
}

export const deleteOrder = (u) => {
  return sendFetch(root + '/api/ajax/deleteOrder/', 'POST', u).then(
    (response) => {
      return response
    }
  )
}

export const createPdf = (u) => {
  return sendFetch(root + '/api/ajax/getInvoice/', 'POST', u).then(
    (response) => {
      return response
    }
  )
}

export const getStatus = () => {
  return sendFetch(root + '/api/ajax/getStatus/', 'GET').then((response) => {
    return response
  })
}

export const changeStatus = (post) => {
  return sendFetch(root + '/api/ajax/setStatusOrder/', 'POST', {
    ...post
  }).then((response) => {
    return response
  })
}

export const changeMoldeStatus = (post) => {
  return sendFetch(root + '/api/ajax/setMolde/', 'POST', {
    ...post
  }).then((response) => {
    return response
  })
}

export const downloadSources = (id) => {
  return sendFetch(root + '/api/ajax/downloadSources/', 'POST', id).then(
    (result) => {
      return result
    }
  )
}

export const duplicateOrder = (id) => {
  return sendFetch(root + '/api/ajax/duplicateOrder/', 'POST', id).then(
    (result) => {
      return result
    }
  )
}

export const setFavorite = (data) => {
  return sendFetch(root + '/api/ajax/setFavorite/', 'POST', data).then(
    (result) => {
      return result
    }
  )
}

export const getPrices = () => {
  return sendFetch(root + '/api/ajax/getPrices/', 'GET').then((result) => {
    return result
  })
}

export const setPrice = (post) => {
  return sendFetch(root + '/api/ajax/setPrices/', 'POST', { ...post }).then(
    (result) => {
      return result
    }
  )
}

export const getFiles = (type = null, ukey = '') => {
  let url = root + '/api/ajax/getFiles/?type=' + type + '&ukey=' + ukey
  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

export const getFavorites = (u) => {
  let url = root + '/api/ajax/getFavorites/?ukey=' + u
  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

export const deleteFavorite = (u) => {
  return sendFetch(root + '/api/ajax/deleteFavorites/', 'POST', u).then(
    (response) => {
      return response
    }
  )
}

export const setFix = (post) => {
  return sendFetch(root + '/api/ajax/setFixOrder/', 'POST', { ...post }).then(
    (response) => {
      return response
    }
  )
}

/* ---- DELIVERIES ----- */
export const getDeliveries = (
  u = null,
  status = null,
  notWhen = null,
  deliveryAt = null,
  createdAt = null,
  fromTwoDaysBefore = null
) => {
  let url = root + '/api/ajax/deliveries/'
  if (u) {
    url += '?u=' + u
  }
  if (status !== null && status !== undefined) {
    url += `${url.indexOf('?') === -1 ? '?' : '&'}status=${status}`
  }

  if (notWhen !== null && notWhen !== undefined) {
    url += `${url.indexOf('?') === -1 ? '?' : '&'}notWhen=${notWhen}`
  }

  if (deliveryAt !== null && deliveryAt !== undefined) {
    url += `${url.indexOf('?') === -1 ? '?' : '&'}deliveryAt=${deliveryAt}`
  }

  if (createdAt !== null && createdAt !== undefined) {
    url += `${url.indexOf('?') === -1 ? '?' : '&'}deliveryAt=${deliveryAt}`
  }

  if (fromTwoDaysBefore !== null && fromTwoDaysBefore !== undefined) {
    url += `${url.indexOf('?') === -1 ? '?' : '&'}fromTwoDaysBefore=1`
  }

  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

export const setNewDeliveryDate = (post) => {
  return sendFetch(root + '/api/ajax/setChangeDeliveryDate/', 'POST', {
    ...post
  }).then((response) => {
    return response
  })
}

export const generateDeliveryResource = (date) => {
  return sendFetch(
    root + '/api/ajax/getDeliveriesPdf/?date=' + date,
    'GET'
  ).then((response) => {
    return response
  })
}

export const generateProductionResource = (date) => {
  return sendFetch(
    root + '/api/ajax/getProductionPdf/?date=' + date,
    'GET'
  ).then((response) => {
    return response
  })
}

export const getInvoices = (date) => {
  let url = root + '/api/ajax/getCustomerInvoices/'
  if (date !== null && date !== undefined) {
    url += `${url.indexOf('?') === -1 ? '?' : '&'}date=${date}`
  }
  return sendFetch(url, 'GET').then((response) => {
    return response
  })
}

export const generateManualInvoice = (invoiceData) => {
  let url = root + '/api/ajax/generateManualInvoice/'
  return sendFetch(url, 'POST', invoiceData).then((response) => {
    return response
  })
}

export const generateInvoiceExport = (startDate, endDate) => {
  let url = root + `/api/ajax/getInvoiceExport/?start=${startDate}${endDate ? '&end='+endDate : ''}`

  return fetch(url, {
    method: 'GET',
    headers: {
      ukey: window.localStorage.getItem('ukey'),
      version: 1
    }
  })
    .then((response) => {
      if (response.status === 200) {
        const cdHeaderValue = response.headers.get('content-disposition')?.split(';')[0]
        if (cdHeaderValue?.toLowerCase() === 'attachment') {
          let filename = "facturas_export.csv"
          try {
            filename = response.headers.get('content-disposition')
              .split(';')
              .find(n => n.includes('filename='))
              .replace('filename=', '')
              .replaceAll('"', '')
              .trim()
            ;
          } catch (e) {}
          response.blob().then((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          })
        } else {
          return response.json()
        }
      } else {
        throw new Error(response.status)
      }
    })
    .catch((err) => {
      console.log('err', err)
      alert('Error al procesar la consulta')
    })

}

/* ---- PROMOTIONS ----- */
export const getPromotions = () => {
  return sendFetch(root + '/api/ajax/getPromotions/', 'GET').then((response) => {
    return response
  })
}

export const setPromotion = (image, description, validFrom, validTo) => {
  const formData = new FormData()
  formData.append('image', image)
  formData.append('description', description)
  formData.append('validFrom', validFrom)
  formData.append('validTo', validTo)
  return fetch(root + '/api/ajax/setPromotion/', {
    method: 'POST',
    headers: {
      ukey: window.localStorage.getItem('ukey'),
      version: '1'
    },
    body: formData
  }).then(
    (response) => {
      return response
    }
  )
}

export const deletePromotion = (id) => {
  return sendFetch(root + '/api/ajax/deletePromotion/', 'POST', {id}).then(
    (response) => {
      return response
    }
  )
}

export const updatePromotion = (promotionId, description, validFrom, validTo) => {
  return sendFetch(root + '/api/ajax/updatePromotion/?p='+promotionId, 'POST', {description, validFrom, validTo})
    .then(
      (response) => {
        return response
      }
    )
}