import React, { useEffect } from 'react';
import DatePicker from "react-multi-date-picker";
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { FORMAT_DATE, FORMAT_SERVER_DATE } from '../../../core/constants/constants'

export const PromotionModal = ({ show, onClose, onCreate, promotionData }) => {

  const [description, setDescription] = React.useState(null);
  const [validFrom, setValidFrom] = React.useState(null);
  const [validTo, setValidTo] = React.useState(null);
  const [imageFile, setImageFile] = React.useState(null);

  useEffect(() => {
    if (!promotionData) return
    setDescription(promotionData.DESCRIPTION)
    setValidFrom(promotionData.VALID_FROM)
    setValidTo(promotionData.VALID_TO)
  }, [promotionData])

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      'image/jpeg',
      'image/png',
      'image/webp'
    ],
    multiple: false,
    maxSize: 1e+6,
    onDropAccepted: (acceptedFiles) => {
      setImageFile(Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      }));
    },
    onDropRejected: () => {
      toast("La imagen no es válida.", {type: toast.TYPE.ERROR})
    },
    required: true
  })

  const handleOnCreate = () => {
    onCreate &&
    onCreate(
      promotionData?.ID,
      description,
      validFrom?.format(FORMAT_SERVER_DATE.toUpperCase()),
      validTo?.format(FORMAT_SERVER_DATE.toUpperCase()),
      imageFile
    )
  };

  const handleOnClose = () => {
    onClose && onClose();
  }

  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">{promotionData ? "Editar" : "Nueva"} promoción</h4>
          </div>
          <div className="modal-body">
            <form role="form" className="row form form-horizontal form-groups-bordered" autoComplete="off">
              <div className="col-xs-12 promocion-modal-dropzone__container">
                <div {...getRootProps({ className: 'promocion-modal-dropzone col-xs-12' })}>
                  <input {...getInputProps()} required={true} />
                  <em>Sólo se aceptan imágenes (jpg, jpeg, png, webp) hasta 1MB</em>
                </div>
                {imageFile &&
                  <div className={"col-xs-12 promotion-thumb__container"}>
                    <div className={"promotion-thumb__thumb"}>
                      <div className={"promotion-thumb__inner"}>
                        <img
                          className={"promotion-thumb__image"}
                          src={imageFile.preview}
                          onLoad={() => {
                            URL.revokeObjectURL(imageFile.preview)
                          }}
                          alt={"Imagen promocion"}
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="col-xs-12 form-group">
                <div className="col-sm-3 text-right">
                  <label className="control-label bold">Válida desde</label>
                </div>
                <DatePicker
                  value={validFrom}
                  render={<DateInput/>}
                  format={FORMAT_DATE.toUpperCase()}
                  onChange={(newDate) => setValidFrom(newDate)}
                  containerClassName={'col-sm-9'}
                />
              </div>
              <div className="col-xs-12 form-group">
                <div className="col-sm-3 text-right">
                  <label className="control-label bold">Válida hasta</label>
                </div>
                <DatePicker
                  value={validTo}
                  render={<DateInput/>}
                  format={FORMAT_DATE.toUpperCase()}
                  onChange={(newDate) => setValidTo(newDate)}
                  containerClassName={'col-sm-9'}
                />
              </div>
              <div className="col-xs-12 form-group">
                <div className={`col-sm-3 text-right col-xs-4`}>
                  <label htmlFor="description" className="control-label bold">Descripción</label>
                </div>
                <div className={`col-sm-9 col-xs-8`}>
                  <textarea
                    id="description"
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder={"Descripción de la promoción (opcional)"}
                    value={description}
                    rows={5}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
              onClick={() => handleOnCreate()}
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const DateInput = ({
  openCalendar,
  value,
  disabled,
  handlerOnChange
}) => {
  return (
    <input
      type={'text'}
      onFocus={() => {
        openCalendar && openCalendar()
      }}
      defaultValue={value}
      onChange={handlerOnChange}
      className={disabled ? 'input-control disabled' : 'input-control'}
      style={{ borderRadius: '3px', height: '3rem' }}
    />
  )
}